<template>
  <div class="event-scan" :class="{ 'default-background': background == '' }" :style="dynamic_background">
    <template v-if="!loading_checkin_detail">
      <header v-if="false">
        <img src="/static/img/checkin-station/logo-sociolla.png" />
      </header>
      <content v-if="is_landscape">
        <div class="left">
          <img :src="logo" class="dynamic-logo" />
          <h2 class="welcome-text">WELCOME TO</h2>
          <h2 class="event-store-name">{{ event_store_name }}</h2>
        </div>
        <div class="separator"></div>
        <div class="right">
          <!-- before scan -->
          <template v-if="!userProfile">
            <div class="scan-qr-title">{{ title }}</div>
            <p class="tagline-before-scan" v-html="tagline_before_scanning"></p>
            <div class="qr-container">
              <qrcode-vue :value="qr_code" :size="is_1080p ? 300 : 196" level="H" class="qr-image"></qrcode-vue>
            </div>
          </template>
          <!-- before scan -->
          <!-- after scan -->
          <template v-else>
            <div class="profile-picture-circle-outer">
              <div class="profile-picture-circle-inner">
                <img :src="userProfile.image" />
              </div>
            </div>
            <h2 class="profile-name">{{ userProfile.name }}</h2>
            <img :src="soco_star_image" class="profile-level" />
            <p class="tagline-after-scan" v-html="tagline_after_scanning"></p>
          </template>
          <!-- after scan -->
        </div>
      </content>
      <content v-else>
        <template v-if="!userProfile">
          <template v-if="!is_1080p">
            <img :src="logo" class="dynamic-logo" />
            <h2 class="welcome-text">WELCOME TO</h2>
            <h2 class="event-store-name">{{ event_store_name }}</h2>
            <p class="tagline-before-scan" v-html="tagline_before_scanning"></p>
            <div class="qr-container">
              <qrcode-vue :value="qr_code" level="H" :size="150" class="qr-image"></qrcode-vue>
            </div>
          </template>
          <template v-else>
            <img :src="logo" class="dynamic-logo" />
            <h2 class="welcome-text">WELCOME TO</h2>
            <h2 class="event-store-name">{{ event_store_name }}</h2>
            <div style="padding: 0 32px">
              <div class="scan-qr-title">{{ title }}</div>
              <p class="tagline-before-scan" v-html="tagline_before_scanning"></p>
            </div>
            <div class="qr-container">
              <qrcode-vue
                :value="qr_code"
                level="H"
                :size="is_1080p ? (is_qr_large_version ? 370 : 300) : 150"
                class="qr-image"
              ></qrcode-vue>
            </div>
          </template>
        </template>
        <template v-else>
          <h2 class="welcome-text">WELCOME TO</h2>
          <h2 class="event-store-name">{{ event_store_name }}</h2>
          <div class="profile-picture-circle-outer">
            <div class="profile-picture-circle-inner">
              <img :src="userProfile.image" />
            </div>
          </div>
          <span v-if="is_show_text_hello" class="hello-text">Halo,</span>
          <h2 class="profile-name">{{ userProfile.name }}</h2>
          <img :src="soco_star_image" class="profile-level" />
          <p class="tagline-after-scan" v-html="tagline_after_scanning"></p>
        </template>
      </content>
      <footer v-if="false">
        <div class="footer-logo">
          <img src="/static/img/checkin-station/soco_logo.png" alt="SOCO Logo" />
        </div>
        <div class="footer-download">
          <img src="/static/img/checkin-station/download_app.png" alt="QR Code" class="download-icons" />
        </div>
      </footer>
    </template>
    <div v-else class="spinner-container">
      <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import QrcodeVue from 'qrcode.vue';

import SocketIO from 'socket.io-client';

const QR_CODE_LIFE_TIME = 10 * 60 * 1000;

const Config = require('~/config/default.env').default;

const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'home',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      userProfile: null,
      qrGenTimer: null,
      logoutTimer: null,
      getTokenTimer: null,
      promotions: '',
      isPromotionLoading: false,
      innerHeight: 0,
      innerWidth: 0,
    };
  },
  computed: {
    is_1080p() {
      return this.innerHeight >= 1080 || this.innerWidth >= 1920;
    },
    is_show_text_hello() {
      return this.innerHeight >= 1920 && !this.is_landscape;
    },
    is_qr_large_version() {
      return this.innerHeight >= 1260 && this.innerWidth >= 540;
    },
    soco_star_image() {
      switch (this.userProfile.user_level.toLowerCase()) {
        case 'diva star':
          return '/static/img/soco-level/diva-star.svg';
        case 'super star':
          return '/static/img/soco-level/super-star.svg';
        case 'rising star':
          return '/static/img/soco-level/rising-star.svg';
        case 'soco star':
        default:
          return '/static/img/soco-level/soco-star.svg';
      }
    },
    dynamic_background() {
      if (this.background_type === 'image') {
        return `background-image: url('${this.background}'); background-size: cover`;
      } else if (this.background_type === 'solid_color') {
        return `background-color: ${this.color}`;
      } else {
        return '';
      }
    },
    title() {
      return this.$store.state.checkinStation.title || 'SCAN QR';
    },
    tagline_before_scanning() {
      return (
        this.$store.state.checkinStation.tagline_before ||
        '<p>Scan QR Code di bawah ini menggunakan aplikasi <strong>SOCO by Sociolla</strong></p>'
      );
    },
    tagline_after_scanning() {
      return (
        this.$store.state.checkinStation.tagline_after ||
        '<p>Dapatkan berbagai penawaran menarik hanya di Sociolla Beauty Wonderland!</p>'
      );
    },
    event_store_name() {
      return this.$store.state.checkinStation.name.toUpperCase() || 'SOCIOLLA BEAUTY WONDERLAND';
    },
    color() {
      return this.$store.state.checkinStation.background_color || '#FFADD2';
    },
    logo() {
      return this.$store.state.checkinStation.logo || '/static/img/checkin-station/store-event-camp-logo.png';
    },
    background_type() {
      return this.$store.state.checkinStation.background_type || 'none';
    },
    is_landscape() {
      return this.innerHeight < this.innerWidth;
    },
    background() {
      if (this.background_type !== 'none') {
        if (this.is_landscape) {
          return this.$store.state.checkinStation.background_desktop;
        } else {
          return this.$store.state.checkinStation.background_mobile;
        }
      } else {
        return '';
      }
    },
    qr_code() {
      return this.$store.state.QRcode.value || '';
    },
    is_vn() {
      return this.$route.query.isvn;
    },
    loading_checkin_detail() {
      return this.$store.state.checkinDetail?.loading;
    },
    is_store_event() {
      return this.$route.path.includes('/store');
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.init();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.qrGenTimer);
    clearTimeout(this.logoutTimer);
  },
  methods: {
    handleResize() {
      this.innerHeight = window.innerHeight;
      this.innerWidth = window.innerWidth;
    },
    init() {
      if (!this.is_store_event) {
        this.$store.dispatch('fetchCheckinDetailById', this.$route.params.id).then(() => {
          this.generateQR();
        });
      } else {
        this.$store.dispatch('fetchCheckinDetailByStoreId', this.$route.params.id).then(() => {
          this.generateQR();
        });
      }

      // re-generate qr code every 10 minutes
      this.qrGenTimer = setInterval(() => {
        if (this.$store.state.QRcode.isLoggedIn) {
          // stop re-generation after logging in
          return clearInterval(this.qrGenTimer);
        }
        this.$store.dispatch('generateQrCode');
      }, QR_CODE_LIFE_TIME);

      const self = this;

      socket.on('qr_code_login', function (data) {
        if (self.$store.state.QRcode.value == data.qr_code) {
          self.$store.commit('authToken', data.token);
          self.$store.dispatch('getToken', { data: self }).then(() => {
            self.userProfile = self.$store.state.auth.userProfile;
            if (!this.is_store_event) {
              try {
                const checkin_detail = self.$store.state.checkinStation;
                const headers = {
                  Authorization: `Bearer ${self.$store.state.auth.authToken}`,
                };
                const url = `${Config.MS_SOCIOLLA_PUBLIC_API_URL}/checkin-logs/${checkin_detail.checkin_id}`;
                axios
                  .post(
                    url,
                    {},
                    {
                      headers: headers,
                    }
                  )
                  .then((res) => {
                    console.log(res);
                  });
              } catch (e) {
                console.log(e);
              }
            }
          });
          self.logoutTimer = setInterval(() => {
            self.userProfile = null;
            // self.$store.dispatch('destroyQrSession');
            clearInterval(self.logoutTimer);
            self.init();
          }, 7000);

          socket.off('qr_code_login');
        }
      });
    },
    generateQR() {
      const checkin_detail = this.$store.state.checkinStation;
      if (checkin_detail.checkin_type === 'campaign' && checkin_detail.campaign_id) {
        this.$store.commit('generateCodeV2', {
          id: checkin_detail.campaign_id,
          qr_type: 'campaignv2',
        });
      } else if (checkin_detail.checkin_type === 'campaign' && checkin_detail.promotion_id) {
        this.$store.commit('generateCodeV2', {
          id: checkin_detail.promotion_id,
          qr_type: 'campaignv1',
        });
      } else {
        const storeId =
          checkin_detail && checkin_detail.store_id && checkin_detail.store_id.length > 0
            ? checkin_detail.store_id
            : this.$route.params.id;
        this.$store.commit('generateCodeV2', {
          id: storeId,
          qr_type:
            checkin_detail && checkin_detail.store_id && checkin_detail.store_id.length > 0 && checkin_detail.is_event
              ? 'microsite'
              : 'store',
        });
      }
    },
    getPromotion() {
      this.isPromotionLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get('/promos')
        .then((response) => {
          if (response.status === 200) {
            this.promotions = response.data.data;
          } else {
            this.promotions = [];
          }
          this.isPromotionLoading = false;
        })
        .catch((error) => {
          this.promotions = [];
          this.isPromotionLoading = false;
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'kokasScanQR';
</style>
